/* Existing styles in App.css */

/* SVG Background Styles */
.bg-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  min-height: 100vh;
}

.bg-section.light {
  background-image: url("./svg/lightsvg.svg");
}

.bg-section.dark {
  background-image: url("./svg/darksvg.svg");
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInUp {
  animation: fadeInUp 0.6s ease-out forwards;
}

/* Target all paths in the SVG */
.bg-section svg path {
  opacity: 0;
  animation: fadeInUp 0.6s ease-out forwards;
}

/* Delay each path's animation */
.bg-section svg path:nth-child(1) {
  animation-delay: 0s;
}
.bg-section svg path:nth-child(2) {
  animation-delay: 0.1s;
}
.bg-section svg path:nth-child(3) {
  animation-delay: 0.2s;
}
.bg-section svg path:nth-child(4) {
  animation-delay: 0.3s;
}
.bg-section svg path:nth-child(5) {
  animation-delay: 0.4s;
}
.bg-section svg path:nth-child(6) {
  animation-delay: 0.5s;
}
.bg-section svg path:nth-child(7) {
  animation-delay: 0.6s;
}
/* Add more if you have more paths */
